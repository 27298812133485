import React, { useState, useEffect, useRef } from "react";
import { useAssessmentsContext } from "../hooks/useAssessmentsContext";
import { useAuthContext } from "../hooks/useAuthContext";

const EditCollaboratorsForm = ({ assessmentId, onClose, onAssessmentUpdate, userRole }) => {
  const { dispatch } = useAssessmentsContext();
  const { user, dispatch: authDispatch } = useAuthContext();
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorEmail, setCollaboratorEmail] = useState("");
  const [error, setError] = useState(null);
  const [collaboratorError, setCollaboratorError] = useState(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedCollaboratorId, setSelectedCollaboratorId] = useState(null);
  const modalRef = useRef();
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}/collaborators`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            }
          }
        );
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || "Error fetching collaborators");
        }

        const sortedCollaborators = sortCollaborators(data.collaborators);
        setCollaborators(sortedCollaborators);
        setIsOwner(sortedCollaborators.some(collab => collab.user._id === user.id && collab.role === "owner"));
      } catch (error) {
        setError("Failed to load collaborators");
      }
    };

    fetchCollaborators();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assessmentId, user]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const currentCollaborator = collaborators.find(
        (collab) => collab.user._id === user.id
      );
  
      if (
        !currentCollaborator ||
        (currentCollaborator.role !== "owner" && currentCollaborator.role !== "admin")
      ) {
        throw new Error("You don't have permission to edit collaborators");
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}/collaborators`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ collaborators }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to update collaborators");
      }
  
      // Fetch the updated assessment data
      const assessmentResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
  
      if (!assessmentResponse.ok) {
        throw new Error("Failed to fetch updated assessment data");
      }
  
      const updatedAssessment = await assessmentResponse.json();
  
      // Call onAssessmentUpdate with the updated assessment data
      onAssessmentUpdate(updatedAssessment);
      onClose();
    } catch (error) {
      console.error("Error updating collaborators:", error);
      setError(error.message);
    }
  };

  const handleAddCollaborator = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!collaboratorEmail.trim()) {
      setCollaboratorError("Please enter an email");
      return;
    }
  
    if (collaboratorEmail === user.email) {
      setCollaboratorError("You are already a collaborator");
      return;
    }
  
    const isDuplicate = collaborators.some((c) => c.user.email === collaboratorEmail);
    if (isDuplicate) {
      setCollaboratorError("Collaborator already added");
      return;
    }
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/info-by-email/${collaboratorEmail}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
  
      const userData = await response.json();
      if (!response.ok) {
        throw new Error(userData.error || "Failed to fetch user details");
      }
  
      const newCollaborator = {
        user: {
          _id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
        },
        role: "viewer",
      };
  
      const updatedCollaborators = sortCollaborators([...collaborators, newCollaborator]);
  
      const updateResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}/collaborators`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ collaborators: updatedCollaborators }),
        }
      );
  
      if (!updateResponse.ok) {
        throw new Error("Failed to add collaborator");
      }
  
      setCollaborators(updatedCollaborators);
      setCollaboratorEmail("");
    } catch (error) {
      console.error("Error adding collaborator:", error);
      setCollaboratorError(error.message);
    }
  };

  const handleRemoveCollaborator = async (collaboratorId) => {
    const collaborator = collaborators.find((collab) => collab.user._id === collaboratorId);

    if (collaborator.role === "owner") {
      throw new Error("Cannot remove the owner from the assessment");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}/collaborators/${collaboratorId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to remove collaborator");
      }

      const updatedCollaborators = sortCollaborators(collaborators.filter((c) => c.user._id !== collaboratorId));
      setCollaborators(updatedCollaborators);
    } catch (error) {
      console.error("Error removing collaborator:", error);
      setError(error.message);
    }
  };

  const handleChangeCollaboratorRole = async (collaboratorId, newRole) => {
    if (newRole === "owner") {
      // Show the confirmation popup for transferring ownership
      setSelectedCollaboratorId(collaboratorId);
      setShowConfirmationPopup(true);
    } else {
      await updateCollaboratorRole(collaboratorId, newRole);
    }
  };

  const updateCollaboratorRole = async (collaboratorId, newRole) => {
    try {
      const currentCollaborator = collaborators.find(
        (collab) => collab.user._id === user.id
      );
  
      if (currentCollaborator.role === "owner" && newRole === "owner") {
        // If the current user is an owner and they are promoting someone to owner, demote themselves to admin
        const updatedCollaborators = sortCollaborators(collaborators.map((collaborator) => {
          if (collaborator.user._id === collaboratorId) {
            return { ...collaborator, role: "owner" };
          }
          if (collaborator.user._id === user.id) {
            return { ...collaborator, role: "admin" };
          }
          return collaborator;
        }));
  
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}/collaborators`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify({ collaborators: updatedCollaborators }),
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to change collaborator role");
        }
  
        setCollaborators(updatedCollaborators);
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}/collaborators/${collaboratorId}/role`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify({ role: newRole }),
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to change collaborator role");
        }
  
        const updatedCollaborators = sortCollaborators(collaborators.map((collaborator) => {
          if (collaborator.user._id === collaboratorId) {
            return { ...collaborator, role: newRole };
          }
          return collaborator;
        }));
  
        setCollaborators(updatedCollaborators);
      }
    } catch (error) {
      console.error("Error changing collaborator role:", error);
      setError(error.message);
    }
  };

  const leaveAssessment = async () => {
    const currentCollaborator = collaborators.find(
      (collab) => collab.user._id === user.id
    );

    if (currentCollaborator.role === "owner") {
      throw new Error("The owner cannot leave the assessment");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}/leave`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to leave the assessment");
      }

      setCollaborators(collaborators.filter((c) => c.user._id !== user.id));
      onClose();
    } catch (error) {
      console.error("Error leaving the assessment:", error);
      setError(error.message);
    }
  };

  const confirmTransferOwnership = async () => {
    setShowConfirmationPopup(false);
    if (selectedCollaboratorId) {
      await updateCollaboratorRole(selectedCollaboratorId, "owner");
      setSelectedCollaboratorId(null);
    }
  };

  const cancelTransferOwnership = () => {
    setShowConfirmationPopup(false);
    setSelectedCollaboratorId(null);
  };

  const sortCollaborators = (collaboratorsList) => {
    const roleOrder = { owner: 1, admin: 2, editor: 3, viewer: 4 };
    return collaboratorsList.sort((a, b) => {
      if (a.user._id === user.id) return -1;
      if (b.user._id === user.id) return 1;
      return roleOrder[a.role] - roleOrder[b.role];
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[70]">
      <div ref={modalRef} className="w-[500px] bg-white rounded-lg shadow m-2 desktop:py-9 mobile:py-4">
        <div className="desktop:px-9 mobile:px-4">
          <h1 className="custom-18-semi text-gray900 mb-1">Edit collaborators</h1>
          <p className="text-gray700 custom-14 mb-5">
            All new users are by default viewers, but only users with editor permissions or higher can edit the assessment. The owner is the only person that can delete the assessment, and they can transfer ownership to another collaborator.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="flex-col space-y-6">
          <div className="flex-col desktop:px-9 mobile:px-4">
            <div className="flex items-end">
              <div className="flex-grow mr-2">
                <label className="block mb-2 custom-14-med text-gray700">Email</label>
                <input
                  type="text"
                  className="input-field"
                  onChange={(e) => setCollaboratorEmail(e.target.value)}
                  value={collaboratorEmail}
                  placeholder="Enter their email"
                />
              </div>
              <button onClick={handleAddCollaborator} className={`${collaboratorEmail ? "blue-button" : "normal-button"}`}>
                Add
              </button>
            </div>
            {collaboratorError && (
              <div className="text-error custom-functional mt-1">
                {collaboratorError}
              </div>
            )}
          </div>

          {error && <div className="text-red-500 mb-5">{error}</div>}

          <div id="CollabDivider" className="flex items-center gap-2">
            <div className="h-[1px] flex-1 bg-borderprimary"></div>
            <h3 className="text-gray600 custom-14-med">Current collaborators: [{collaborators.length}]</h3>
            <div className="h-[1px] flex-1 bg-borderprimary"></div>
          </div>

          <div className="h-[200px] overflow-y-auto flex flex-col">
          {collaborators.map((collaborator) => {
  const isCurrentUser = collaborator.user._id === user.id;
  const isOwner = collaborator.role === "owner";
  const isAdmin = collaborator.role === "admin";

  // Get initials
  const initials = `${collaborator.user.firstName[0]}${collaborator.user.lastName[0]}`;

  return (
    <div
      key={collaborator.user._id}
      className="flex items-center justify-between hover:bg-bghover rounded-lg desktop:px-9 mobile:px-4 py-3"
    >
      <div className="flex items-center">
        <div className="w-8 h-8 rounded-full bg-nafsnBlue flex items-center justify-center mr-3 text-white custom-14-semi">
          {initials}
        </div>
        <div>
          <div className="text-gray700 custom-14-med">
            {`${collaborator.user.firstName} ${collaborator.user.lastName}`}{" "}
            {isCurrentUser ? " (you)" : ""}
          </div>
          <div className="text-gray700 custom-14">{collaborator.role.charAt(0).toUpperCase() +
                                collaborator.role.slice(1)}</div>
        </div>
      </div>
      {isCurrentUser ? (
        !isOwner && (
          <button className="small-button" onClick={leaveAssessment}>
            Withdraw
          </button>
        )
      ) : (
        <>
          {userRole === "owner" && collaborator.role !== "owner" && (
            <div className="relative flex flex-row gap-2">
              <select
                value={collaborator.role}
                onChange={(e) =>
                  handleChangeCollaboratorRole(
                    collaborator.user._id,
                    e.target.value
                  )
                }
                className="dropdown small-button"
              >
                <option value="viewer">Viewer</option>
                <option value="editor">Editor</option>
                <option value="admin">Admin</option>
                <option value="owner">Owner</option>
              </select>
              <button
                onClick={() => handleRemoveCollaborator(collaborator.user._id)}
                className="small-button"
              >
                Remove
              </button>
            </div>
          )}
          {userRole === "admin" && collaborator.role === "viewer" && (
            <div className="relative flex flex-row gap-2">
              <select
                value={collaborator.role}
                onChange={(e) =>
                  handleChangeCollaboratorRole(
                    collaborator.user._id,
                    e.target.value
                  )
                }
                className="dropdown small-button"
              >
                <option value="viewer">Viewer</option>
                <option value="editor">Editor</option>
                <option value="admin">Admin</option>
              </select>
              <button
                onClick={() => handleRemoveCollaborator(collaborator.user._id)}
                className="small-button"
              >
                Remove
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
})}




          </div>

          <div className="mt-8 flex justify-end space-x-2 desktop:px-9 mobile:px-4">
            <button className="pop-up-cancel" onClick={onClose}>
              Cancel
            </button>
            <button className="pop-up-cta" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
      {showConfirmationPopup && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center z-[80]">
          <div className="bg-white p-9 rounded w-[500px]">
            <h4 className="custom-18-semi text-gray900 mb-1">
              Are you sure you want to transfer ownership?
            </h4>
            <p className="text-gray700 custom-14 mb-5">
              This action will make the selected collaborator the new owner and you will be changed to admin.
            </p>
            <div className="mt-8 flex justify-end space-x-2">
              <button className="pop-up-cancel" onClick={cancelTransferOwnership}>
                Cancel
              </button>
              <button className="pop-up-cta" onClick={confirmTransferOwnership}>
                Confirm Transfer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditCollaboratorsForm;
