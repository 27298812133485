import React, { useState, useEffect, useRef } from "react";
import { useAssessmentsContext } from "../hooks/useAssessmentsContext";
import { useAuthContext } from "../hooks/useAuthContext";

const EditAssessmentForm = ({
  assessmentId,
  onClose,
  onAssessmentUpdate,
}) => {
  const { dispatch } = useAssessmentsContext();
  const { user } = useAuthContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [descError, setDescError] = useState(null);
  const modalRef = useRef();

  useEffect(() => {
    const fetchAssessmentDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || "Error fetching assessment");
        }
        setName(data.name);
        setDescription(data.description);
      } catch (error) {
        setError("Failed to load assessment details");
      }
    };

    fetchAssessmentDetails();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assessmentId, user.token]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!name) {
      setNameError("Please enter a name for the assessment");
      return;
    }
  
    if (!description) {
      setDescError("Please enter a short description for the assessment");
      return;
    }
  
    try {
      const updatedAssessment = {
        name,
        description,
      };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
        {
          method: "PATCH",
          body: JSON.stringify(updatedAssessment),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
  
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "Failed to update assessment");
      }
  
      // Fetch the updated assessment to get the full details of collaborators
      const assessmentResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
  
      if (!assessmentResponse.ok) {
        throw new Error("Failed to fetch updated assessment data");
      }
  
      const updatedAssessmentData = await assessmentResponse.json();
  
      const collaborators = updatedAssessmentData.collaborators || [];
      console.log("Collaborators after update:", collaborators);
      const currentCollaborator = collaborators.find(
        (collab) => collab.user._id === user.id
      );
  
      console.log("Current Collaborator:", currentCollaborator);
  
      if (
        !currentCollaborator ||
        (currentCollaborator.role !== "owner" && currentCollaborator.role !== "admin")
      ) {
        throw new Error("You don't have permission to edit this assessment");
      }
  
      // Notify the parent component (Themes) about the update
      onAssessmentUpdate(updatedAssessmentData);
  
      dispatch({ type: "UPDATE_ASSESSMENT", payload: updatedAssessmentData });
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };
  

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[70]">
      <div
        ref={modalRef}
        className="w-[500px] desktop:p-9 mobile:p-4 bg-white rounded-lg shadow m-2"
      >
        <h1 className="custom-18-semi text-gray900 mb-4">Edit assessment</h1>
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <form onSubmit={handleSubmit} className="flex-col space-y-6">
          <div className="">
            <label className="block mb-2 custom-14-med text-gray700">
              Assessment Name:
            </label>
            <input
              type="text"
              className="input-field"
              onChange={(e) => {
                setName(e.target.value);
                if (nameError) setNameError(null);
              }}
              value={name}
              placeholder="Enter a name for the assessment"
            />
            {nameError && (
              <div className="text-error custom-functional mt-1">
                {nameError}
              </div>
            )}
          </div>

          <div className="">
            <label className="block mb-2 custom-14-med text-gray700">
              Short Description:
            </label>
            <input
              type="text"
              className="input-field"
              onChange={(e) => {
                setDescription(e.target.value);
                if (descError) setDescError(null);
              }}
              value={description}
              placeholder="Enter a short description"
            />
            {descError && (
              <div className="text-error custom-functional mt-1">
                {descError}
              </div>
            )}
          </div>

          <div className="mt-8 flex justify-end space-x-2">
            <button className="pop-up-cancel" onClick={onClose}>
              Cancel
            </button>
            <button className="pop-up-cta" type="submit">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAssessmentForm;
