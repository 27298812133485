import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";
import { AssessmentsContextProvider } from "./context/AssessmentsContext";
import { AuthContextProvider } from "./context/AuthContext";

// Initialize GA4
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <AssessmentsContextProvider>
        <App />
      </AssessmentsContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
