import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useAssessmentsContext } from "../hooks/useAssessmentsContext";
import { useIsMobile } from "../hooks/useIsMobile";

import NewAssessmentForm from "../homecomponents/NewAssessmentForm";
import EditProfileForm from "../homecomponents/EditProfileForm";
import AssessmentPreview from "../homecomponents/AssessmentPreview";
import CaratGuideComponent from "../homecomponents/CaratGuideComponent";
import PreparationComponent from "../homecomponents/PreparationComponent";
import ScoringComponent from "../homecomponents/ScoringComponent";
import InterpretationComponent from "../homecomponents/InterpretationComponent";
import DefinitionsComponent from "../homecomponents/DefinitionsComponent";
import Bookmarks from "../homecomponents/Bookmarks";
import Footer from "../components/Footer";
import Shimmer from "../components/Shimmer";

const Home = () => {
  const { assessments, dispatch } = useAssessmentsContext();
  const { user } = useAuthContext();

  const [showNewAssessmentModal, setShowNewAssessmentModal] = useState(false);
  const [activeTab, setActiveTab] = useState("Assessments");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (activeTab === "Assessments") {
      changeTab(null, "Assessments");
    }

    const fetchAssessments = async () => {
      if (!user) {
        setLoading(false);
        return;
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const json = await response.json();

        if (response.ok) {
          console.log("Fetched assessments:", json);
          dispatch({ type: "SET_ASSESSMENTS", payload: json });
        } else {
          console.error("Failed to fetch assessments:", json);
        }
      } catch (error) {
        console.error("Error fetching assessments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments();
  }, [dispatch, user]);

  const tabContainerRef = useRef(null);
  const progressButtonRef = useRef(null);

  const changeTab = (event, tabName) => {
    setActiveTab(tabName);

    const slider = document.getElementById("slider");
    if (event) {
      const tab = event.currentTarget;

      slider.style.width = `${tab.offsetWidth}px`;
      slider.style.left = `${tab.offsetLeft}px`;
    } else {
      const firstTab = tabContainerRef.current?.children[0];
      if (firstTab) {
        slider.style.width = `${firstTab.offsetWidth}px`;
        slider.style.left = `${firstTab.offsetLeft}px`;
      }
    }
  };

  const changeTabMobile = (tabName) => {
    setActiveTab(tabName);
  };

  const getButtonStyle = (tabName) => {
    return activeTab === tabName
      ? "px-4 py-2 text-title custom-functional font-semibold bg-cloud tablet:w-[130px] desktop:w-[200px] text-left"
      : "px-4 py-2 text-stone custom-functional tablet:w-[130px] desktop:w-[200px] text-left hover:custom-functional-med";
  };

  const toggleNewAssessmentModal = () => {
    setShowNewAssessmentModal(!showNewAssessmentModal);
  };

  const filteredAssessments =
    assessments?.filter(
      (assessment) =>
        !assessment.isDeleted &&
        assessment.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const [breadcrumbTop, setBreadcrumbTop] = useState("80px");
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setBreadcrumbTop("-50px");
      } else {
        setBreadcrumbTop("24px");
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleAssessmentDeleted = (deletedAssessmentId) => {
    dispatch({
      type: "DELETE_ASSESSMENT",
      payload: deletedAssessmentId,
    });
  };

  return (
    <div>
      <div
        id="Overall-Container"
        className="flex-col justify-between items-center w-full mx-auto mt-20 pb-32 margin-adaptive"
      >
        <div id="Spacer" className="h-[24px]"></div>
        <div id="Main-Content" className="flex-col space-y-8">
          <div id="Intro-content" className=" flex flex-col gap-6">
            <div id="intro-info-div" className="flex items-center z-[55] gap-4">
              <div className="flex-col flex gap-1">
                <h3 className=" text-gray900 custom-30">
                  Hello {user.firstName},
                </h3>
                <p className="text-gray600 custom-16">
                  If you would like an offline version of CARAT,{" "}
                  <a
                    href="https://www.foodsystemsnetwork.org/docs/CART_Fillable_PDF_Oct_2023.pdf"
                    className="text-nafsnBlue underline"
                    target="_blank"
                  >
                    click here
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="relative flex flex-col w-full tablet:block mobile:hidden">
              <div className="flex w-full gap-4" ref={tabContainerRef}>
                <button
                  ref={progressButtonRef}
                  className={`tab-button ${
                    activeTab === "Assessments" ? "active" : ""
                  }`}
                  onClick={(e) => changeTab(e, "Assessments")}
                >
                  Assessments
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "Bookmarks" ? "active" : ""
                  }`}
                  onClick={(e) => changeTab(e, "Bookmarks")}
                >
                  Bookmarks
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "EditProfile" ? "active" : ""
                  }`}
                  onClick={(e) => changeTab(e, "EditProfile")}
                >
                  Profile
                </button>
              </div>
              <div
                id="background-bar"
                className="h-[1px] bg-bordersecondary absolute bottom-0 w-full"
              ></div>
              <div
                id="slider"
                className="h-[2px] bg-nafsnBlue absolute bottom-0 transition-all duration-300 ease-in-out"
              ></div>
            </div>
          </div>

          <div
            className="flex-col sticky top-20 bg-white rounded transition-all duration-700 ease-in-out z-[54]"
            style={{ top: breadcrumbTop }}
          >
            <div className="flex w-full tablet:hidden desktop:hidden mt-[-60px] pt-[60px]">
              <button
                className={`custom-14-med flex-1 text-center py-4 border-b-2 ${
                  activeTab === "Assessments"
                    ? "border-nafsnBlue border-b-2 text-nafsnBlue"
                    : "border-transparent text-gray600"
                }`}
                onClick={() => changeTabMobile("Assessments")}
              >
                Assessments
              </button>
              <button
                className={`custom-14-med flex-1 text-center py-4 border-b-2 ${
                  activeTab === "EditProfile"
                    ? "border-nafsnBlue border-b-2 text-nafsnBlue"
                    : "border-transparent text-gray600"
                }`}
                onClick={() => changeTabMobile("EditProfile")}
              >
                Edit Profile
              </button>
              <button
                className={`custom-14-med flex-1 text-center py-4 border-b-2 ${
                  activeTab === "Bookmarks"
                    ? "border-nafsnBlue border-b-2 text-nafsnBlue"
                    : "border-transparent text-gray600"
                }`}
                onClick={() => changeTabMobile("Bookmarks")}
              >
                Bookmarks
              </button>
            </div>
            <div
              id="Divider"
              className="h-[1px] w-full bg-bordersecondary tablet:hidden"
            ></div>
          </div>

          <div id="Lower section" className="">
            {activeTab === "Assessments" && (
              <div className="assessments flex-grow space-y-6">
                <div className="flex-row flex justify-between gap-3">
                  <div className="relative mobile:flex mobile:flex-grow desktop:max-w-[400px]">
                    <input
                      type="text"
                      className="search-bar"
                      placeholder="Search assessments..."
                      style={{
                        backgroundImage: "url('/assets/search-lg.png')",
                        backgroundPosition: "10px center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "20px",
                      }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>

                  <button
                    className="normal-button "
                    onClick={toggleNewAssessmentModal}
                  >
                    <img
                      src="/assets/plus.png"
                      alt="Plus Icon"
                      className="w-5 h-5"
                    />
                    {!isMobile && <p>New assessment</p>}
                  </button>
                </div>

                {loading ? (
                  <div className="flex justify-center items-center min-h-[300px]">
                    <Shimmer />
                  </div>
                ) : (
                  <div
                    id="Assessment-Divs"
                    className={`${
                      filteredAssessments && filteredAssessments.length > 0
                        ? "desktop:grid desktop-lg:grid-cols-3 desktop:grid-cols-2 desktop:gap-4"
                        : ""
                    } flex flex-col gap-4`}
                  >
                    {filteredAssessments && filteredAssessments.length > 0 ? (
                      filteredAssessments
                        .sort(
                          (a, b) =>
                            new Date(b.updatedAt) - new Date(a.updatedAt)
                        )
                        .map((assessment) => (
                          <AssessmentPreview
                            key={assessment._id}
                            assessment={assessment}
                            onAssessmentDeleted={handleAssessmentDeleted} // Pass the callback function here
                          />
                        ))
                    ) : (
                      <div className="empty-message flex-grow min-h-[300px] flex items-center justify-center">
                        <p>You have no assessments</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {activeTab === "EditProfile" && (
              <div className="assessments flex-grow space-y-6">
                <EditProfileForm />
              </div>
            )}

            {activeTab === "Bookmarks" && (
              <div className="assessments flex-grow space-y-6">
                <Bookmarks />
              </div>
            )}

            {activeTab === "CARATGuide" && (
              <div className="assessments flex-grow space-y-6">
                <h2 className="text-title custom-title">
                  Introduction and Themes
                </h2>
                <CaratGuideComponent />
              </div>
            )}

            {activeTab === "Preparation" && (
              <div className="assessments flex-grow space-y-6">
                <h2 className="text-title custom-title">
                  Preparing to use CARAT
                </h2>
                <PreparationComponent />
              </div>
            )}

            {activeTab === "Scoring" && (
              <div className="assessments flex-grow space-y-6">
                <h2 className="text-title custom-title">
                  Scoring and using the audit tool
                </h2>
                <ScoringComponent />
              </div>
            )}

            {activeTab === "Interpretation" && (
              <div className="assessments flex-grow space-y-6">
                <h2 className="text-title custom-title">
                  How to interpret your scores
                </h2>
                <InterpretationComponent />
              </div>
            )}

            {activeTab === "Definitions" && (
              <div className="assessments flex-grow space-y-6">
                <h2 className="text-title custom-title">Definitions</h2>
                <DefinitionsComponent />
              </div>
            )}
          </div>
        </div>
        {showNewAssessmentModal && (
          <NewAssessmentForm onClose={toggleNewAssessmentModal} />
        )}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
